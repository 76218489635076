import {
  RowData,
  Table as ITable,
  flexRender,
  Row,
  Cell,
} from "@tanstack/react-table";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from "@winclap-platform/ui/components/accordion";
import { Loader } from "@winclap-platform/ui/components/loader";
import { Separator } from "@winclap-platform/ui/components/separator";
import {
  SpanRow,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@winclap-platform/ui/components/table";
import { Frown } from "lucide-react";
import { TablePaginator } from "./table-paginator";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@winclap-platform/ui/components/tooltip";
import { useLayoutEffect, useRef, useState } from "react";

export interface TableProps<TData extends RowData> {
  table: ITable<TData>;
  isLoading?: boolean;
  accordionContent?: (row: Row<TData>) => React.ReactNode;
  emptyMessage?: string;
  onRowClick?: (row: Row<TData>) => void;
  rowClassName?: (row: Row<TData>) => string;
  tablePaginator?: boolean;
}

export const TableLayout = <TData extends RowData>({
  table,
  isLoading,
  accordionContent,
  emptyMessage,
  onRowClick,
  rowClassName,
  tablePaginator = true,
}: TableProps<TData>) => {
  const hasData = table.getRowModel().rows.length > 0;

  return (
    <section className="mb-6 flex h-full flex-col justify-between gap-6">
      <Table className="overflow-hidden border-white bg-white">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className={header.column.columnDef.meta?.headClassName}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <Accordion type="single" collapsible asChild>
          <TableBody>
            {isLoading ? (
              <SpanRow>
                <Loader />
              </SpanRow>
            ) : hasData ? (
              table.getRowModel().rows.map((row) => (
                <AccordionItem
                  value={row.id}
                  asChild
                  key={row.id}
                  className="data-[state=false]:border-b-0"
                >
                  <>
                    <TableRow
                      data-state={row.getIsSelected() && "selected"}
                      className={rowClassName?.(row)}
                      onClick={() => onRowClick?.(row)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TruncatedCell key={cell.id} cell={cell} />
                      ))}
                    </TableRow>
                    {accordionContent && (
                      <AccordionContent asChild>
                        <TableRow className="border-0 from-transparent to-transparent">
                          <TableCell colSpan={row.getVisibleCells().length}>
                            <Separator className="-mt-2" />
                            {accordionContent(row)}
                          </TableCell>
                        </TableRow>
                      </AccordionContent>
                    )}
                  </>
                </AccordionItem>
              ))
            ) : (
              <SpanRow>
                <h3 className="mb-1 flex w-full items-center justify-center font-semibold">
                  <Frown className="mr-2 size-5" />
                  {"We couldn't find any results"}
                </h3>
                <p className="text-xs">
                  {emptyMessage || "Please try again with different filters."}
                </p>
              </SpanRow>
            )}
          </TableBody>
        </Accordion>
        {table.getRowModel().rows.length > 0 &&
          table
            .getFooterGroups()
            .some((footerGroup) =>
              footerGroup.headers.some(({ column }) => !!column.columnDef.footer),
            ) && (
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  {footerGroup.headers.map((footer) => {
                    return (
                      <TableCell key={footer.id} className="h-[52px] py-2">
                        {footer.isPlaceholder
                          ? null
                          : flexRender(
                              footer.column.columnDef.footer,
                              footer.getContext(),
                            )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableFooter>
          )}
      </Table>
      {tablePaginator && <TablePaginator table={table} />}
    </section>
  );
};

export interface TruncatedCellProps<TData extends RowData> {
  cell: Cell<TData, unknown>;
}

export const TruncatedCell = <TData extends RowData>({
  cell,
}: TruncatedCellProps<TData>) => {
  const cellRef = useRef(null);
  const isTruncated = useIsTruncated(cellRef);

  return (
    <TableCell
      key={cell.id}
      style={{ maxWidth: cell.column.columnDef.maxSize }}
      className="h-[52px] py-2"
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="truncate" ref={cellRef}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        </TooltipTrigger>
        {isTruncated && (
          <TooltipContent align="start">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TooltipContent>
        )}
      </Tooltip>
    </TableCell>
  );
};

export function isTruncated(domElement: Element): boolean {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollWidth
  return domElement.scrollWidth > domElement.clientWidth;
}

export function useIsTruncated<T extends Element>(
  ref: React.MutableRefObject<null | T>,
): boolean {
  const [isTruncatedState, setIsTruncatedState] = useState(false);

  useLayoutEffect(() => {
    if (ref.current !== null) {
      const isTextTruncated = isTruncated(ref.current);
      if (isTruncatedState !== isTextTruncated) {
        setIsTruncatedState(isTextTruncated);
      }
    }
  }, [isTruncatedState, ref]);

  return isTruncatedState;
}
