import { HeaderContext, RowData } from "@tanstack/react-table";
import { ChevronsUpDown, ChevronUp, ChevronDown } from "lucide-react";
import { Button } from "@winclap-platform/ui/components/button";
import { cn } from "@winclap-platform/ui/utils/tailwind";

export const Header = <TDataset extends RowData>(
  context: HeaderContext<TDataset, unknown>,
) => {
  const columnName = context.column.columnDef.meta?.label || context.column.id;

  return context.column.columnDef.enableSorting ? (
    <Button
      variant="ghost"
      size="sm"
      onClick={() =>
        context.column.toggleSorting(context.column.getIsSorted() === "asc")
      }
      className={cn(
        "-ml-3 text-sm text-black hover:text-black",
        context.column.getIsSorted() && "font-semibold",
      )}
    >
      {columnName}
      {context.column.getIsSorted() === "desc" ? (
        <ChevronDown className="text-primary-200 group-hover:text-primary-400 size-4 !stroke-2" />
      ) : context.column.getIsSorted() === "asc" ? (
        <ChevronUp className="text-primary-200 group-hover:text-primary-400 size-4 !stroke-2" />
      ) : (
        <ChevronsUpDown className="text-primary-200 group-hover:text-primary-400 size-4" />
      )}
    </Button>
  ) : (
    columnName
  );
};

export const SortableHeader = <TDataset extends RowData>({
  context,
  label,
}: {
  context: HeaderContext<TDataset, unknown>;
  label?: string;
}) => (
  <Button
    variant="ghost"
    size="sm"
    onClick={() =>
      context.column.toggleSorting(context.column.getIsSorted() === "asc")
    }
    className={cn(
      "-ml-3 text-sm text-black hover:text-black",
      context.column.getIsSorted() && "font-semibold",
    )}
  >
    {label || context.column.id}
    {context.column.getIsSorted() === "desc" ? (
      <ChevronDown className="text-primary-200 group-hover:text-primary-400 ml-2 size-4 !stroke-2" />
    ) : context.column.getIsSorted() === "asc" ? (
      <ChevronUp className="text-primary-200 group-hover:text-primary-400 ml-2 size-4 !stroke-2" />
    ) : (
      <ChevronsUpDown className="text-primary-200 group-hover:text-primary-400 ml-2 size-4" />
    )}
  </Button>
);

export const ActionsHeader = <TDataset extends RowData>(
  context: HeaderContext<TDataset, unknown>,
) => {
  const columnName = context.column.columnDef.meta?.label || context.column.id;
  return <div className="sr-only">{columnName}</div>;
};
